import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/HomeView.vue'),
    },
    // {
    //   path: '/ping',
    //   name: 'ping',
    //   component: () => import('@/views/PingView.vue'),
    // },
    {
      path: '/:name',
      component: () => import('@/views/[name].vue'),
      props: true,
    },
  ],
})

export default router

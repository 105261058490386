<script setup lang="ts">
import { ref } from 'vue'
import { RouterLink, RouterView } from 'vue-router'
import Header from '@/components/Header.vue'
import ReloadPrompt from '@/components/ReloadPrompt.vue'

const routeName = ref('hello-world')
</script>

<template>
  <ReloadPrompt />

  <div class="mx-auto my-16 prose prose-orange">
    <header>
      <img
        alt="Vue logo"
        src="/android-chrome-192x192.png"
        width="125"
        height="125"
      />

      <Header message="It works!" />

      <hr />

      <input type="text" v-model="routeName" />
      <nav class="mt-8">
        <RouterLink
          v-for="(link, i) in [
            ['/', 'Home'],
            // ['/ping', 'Ping'],
            ['/' + routeName, 'Dynamic'],
          ]"
          active-class="font-bold bg-gray-100"
          class="p-4"
          :class="{ 'border-l': i > 0 }"
          :to="link[0]"
        >
          {{ link[1] }}
        </RouterLink>
      </nav>

      <hr />
    </header>

    <RouterView />
  </div>
</template>
